@import '../utils/variables.scss';

.main {
    margin-top: 10vh;
    background-color: $poke-light;
    min-height: 77vh;
    .poke-loader {
        max-width: 100vw;
    }

    @media screen and (min-width: 768px) and (max-width: 1200px) {
        min-height: 77vh;
    }
    @media screen and (min-width: 1200px) {
        min-height: 77vh;    
    }
}
