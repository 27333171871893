@import '../utils/variables.scss';


footer {
    background-color: $poke-dark;
    width: 100vw;

    &>.footer-img {
        max-width: 100vw;
        height: auto;
        display: none;
        @media screen and (min-width: 1030px) {
            display: block;
        }

    }

    &>button {
        margin-top: 30px;
        min-width: 30vw;
        min-height: 70px;

        & img {
            height: 40px;
            width: 47px;
        }
        @media screen and (min-width: 768px) and (max-width: 1000px) {
            min-width: 15vw;
        }

        @media screen and (min-width: 1000px) {
            min-width: 8vw;
            min-height: 40px;
            & img {
                height: 35px;
                width: 42px
            }
        }

    }

    &>h4 {
        color: $poke-light;
        margin: 0px;
        text-decoration: none;
        padding: 20px 10px;
        font-size: 1.3rem;

        &>a {
            color: $poke-light;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            &:hover {
                color: $poke-blue;
            }
            img {
                height: 20px;
                width: 20px;
            }
        }
    }
}