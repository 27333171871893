@import '../utils/mixins.scss';

.new {
    padding: 5%;

    h1 {
        background-image: url('../../assets/create-pokemon.webp');
        @include bg-title;
        
    }

    form {
        margin: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        .error-message {
            color: red;
        }

        input[type="number"],
        input[type="text"],
        input[type="url"],
        select {
            padding: 0.5rem;
            border: 1px solid gray;
            border-radius: 0.25rem;
            font-size: 1.1rem;
            min-width: 60vw;
            @media screen and (min-width: 768px) and (max-width: 1000px) {
                font-size: 1.3rem;
                min-width: 50vw;
            }
            @media screen and (min-width: 1000px) {
                font-size: 1.2rem;
                min-width: 40vw;
            }

            &.error-input {
                border-color: red;
            }
        }

        select {
            appearance: none;
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="16" height="16" fill="none" stroke="%23333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"%3E%3Cpath d="M30 10l-14 14-14-14"%3E%3C/path%3E%3C/svg%3E');
            background-repeat: no-repeat;
            background-position: right 0.7em top 50%, 0 0;
            background-size: 0.65em auto, 100%;
            padding-right: 1.5em;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
        }
    }

    article {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            font-size: 1.25rem;
            margin-bottom: 1rem;
        }
        .pokemon-created-img {
            margin-bottom: 30px;
            max-width: 100%;
            border-radius: 10px;
            border: solid 1px black;
            @media screen and (min-width: 768px) and (max-width: 1000px) {
                max-width: 60%;
            }
            @media screen and (min-width: 1000px) {
                max-width: 45%;    
            }
        }
    }
    .professor-oak-img {
        max-height: 240px;
        @media screen and (min-width: 768px) and (max-width: 1000px) {
            max-height: 250px;
        }
        @media screen and (min-width: 1000px) {
            max-height: 300px;;    
        }
    }
}