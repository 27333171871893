.poke-carousel {
    min-height: 50vh;
}

.carousel-image {
    max-height: 300px;
    width: auto;
    margin-bottom: 20px;
}

.poke-card {
    align-self: center;
    justify-self: center;
    margin-bottom: 100px;
    &>h1 {
        margin-top: 40px;
    }
    &>h2 {
        margin-top: 20px;
        margin-bottom: 40px;
    }
    &>div {
        display: inline;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}