@import '../utils/variables.scss';


.home {
    &> .home-title-pokemon {
        margin-top: 20px;
        // height: 20vh;
        height: 169.20px;
        width: 300px;
        @media screen and (min-width: 768px) and (max-width: 1000px) {
            height: 253.80px;
            width: 450px;
        }

        @media screen and (min-width: 1000px) {
            height: 203.04px;
            width: 360px;
        }
    }
    &> .home-start-article{
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 50px 10%;
        border-radius: 10px;
        background: $poke-blue;
        box-shadow:  21px 21px 55px #5a5a5a, -21px -21px 55px #ffffff;
        @media screen and (min-width: 768px) and (max-width: 1000px) {
            margin: 30px 20%;
        }

        @media screen and (min-width: 1000px) {
            margin: 50px 25%;

        }
        &> h3{
            color: $poke-light;
      
         }
        &> div{
            margin: 20px 0px;
            flex-direction: row;
            display: flex;
            & .home-left-button {
                border-radius: 10px 0px 0px 10px;
            }
            & .home-right-button {
                border-radius: 0px 10px 10px 0px;
            }
            button {
                font-size: 1.3rem;
                
                @media screen and (min-width: 1000px) {
                    font-size: 1.3rem;                
                }
            }
        }
    }
}



